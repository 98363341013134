.DocumentFolderSection {
  display: flex;
  flex-flow: column;
  height: 100%;
  padding-bottom: 5px;
  header {
    position: relative;
    height: auto;
    top: 0;
    margin: 0 0 16px;
    padding: 0 0 22px;
    background: transparent;
    border-bottom: 1px solid #DAE3ED;
    h3 {
      position: relative;
      padding-left: 34px;
      margin: 0;
      line-height: 37px;
      color: #56606B;
      font-family: 'Noto Sans KR';
      font-size: 20px;
      font-weight: 700;
      letter-spacing: -0.4px;
      &:before {
        content: "";
        position: absolute;
        top: 8px;
        left: 6px;
        display: inline-block;
        width: 20px;
        height: 20px;
        background: url("../../../icons/ic-pencil.svg") no-repeat center;
      }
    }
  }
  .btn-group {
    display: flex;
    .btn {
      width: auto;
      height: 36px;
      margin: 0;
      border-radius: 3px !important;
      border: none;
      color: #424952;
      font-family: 'Noto Sans KR';
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0.05px;
      background-color: #DAE3ED;
      &:hover {
        background-color: #BEC9D4;
      }
      &.btn-primary {
        flex: 0 0 auto;
        width: 102px;
        margin-right: 5px;
        color: #FFFFFF;
        background-color: #E0205C;
        &:hover {
          background-color: #F55D8D;
        }
        &:active,
        &:focus {
          background-color: #CB0F4A;
        }
      }
    }
  }
  .list-group {
    margin-top: 16px;
    overflow-y: auto;
    .list-group-item {
      position: relative;
      display: flex;
      height: 32px;
      line-height: 32px;
      padding: 0 0 0 31px;
      background-color: transparent;
      border: none;
      border-radius: 3px;
      cursor: pointer;
      outline: none;
      &+.list-group-item {
        margin-top: 7px;
      }
      &:before {
        content: "";
        position: absolute;
        top: 6px;
        left: 4px;
        width: 20px;
        height: 20px;
        background: url("../../../icons/ic-folder.svg") no-repeat center;
      }
      &:hover {
        background-color: #E9EFF5;
      }
      &.active {
        border-top-width: 0;
        background-color: #F0FDFF;
        box-shadow: none;
        &:before {
          background: url("../../../icons/ic-folder-on.svg") no-repeat center;
        }
        p {
          color: #2C3238;
        }
      }
      &.recent {
        &:before {
          background: url("../../../icons/ic-recent.svg") no-repeat center;
        }
        &:hover {
          background-color: #E9EFF5;
        }
        &.active {
          &:before {
            background: url("../../../icons/ic-recent-on.svg") no-repeat center;
          }
        }
      }
      &.shared {
        &:before {
          background: url("../../../icons/ic-share-mini.svg") no-repeat center;
        }
        &:hover {
          background-color: #E9EFF5;
        }
        &.active {
          &:before {
            background: url("../../../icons/ic-share-mini-on.svg") no-repeat center;
          }
        }
      }
      p {
        margin: 0;
        color: #8996A3;
        font-family: 'Noto Sans KR';
        font-size: 15px;
        font-weight: 700;
        letter-spacing: -0.2px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .badge {
        padding: 0 8px 0 10px;
        line-height: 32px;
        color: #26B9D1;
        font-family: 'Noto Sans KR';
        font-size: 12px;
        font-weight: 700;
        letter-spacing: 0.1px;
      }
    }
  }
}